import React from 'react';
import {
  Checkbox,
  CommandButton, DefaultButton, mergeStyleSets, MessageBarType, PrimaryButton, Separator, Stack, Text,
  TextField,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import structuredClone from 'core-js/stable/structured-clone';
import Theme from '../../shared/Theme';
import MessageBar from '../../shared/MessageBar';
import FeatureUsage from './FeatureUsage';
import StackTokens from '../../shared/StackTokens';
import Store from '../../shared/state/Store';
import LinkButton from '../../shared/LinkButton';
import Routing from '../../shared/Routing';
import TextFieldBuffered from '../../shared/TextFieldBuffered';
import GraphUtils from '../../modeller/GraphUtils';
import AdminApi from '../../shared/util/AdminApi';
import AccountsState from './AccountsState';
import UiReducer from '../../shared/state/UiReducer';

const componentStyles = mergeStyleSets({
  refreshButton: {
    height: '22px',
    selectors: {
      i: {
        margin: 0,
        padding: 0,
      },
      span: {
        display: 'inline-block',
        width: 'min-content',
        paddingTop: '2px',
      },
    },
  },
  smallTextInput: {
    width: '50px',
  },
  checkboxContainer: {
  },
  addDomainButton: {
    width: '34px',
    selectors: {
      i: {
        fontSize: '24px',
        margin: 0,
      },
    },
  },
});

export default ({ subscriptionAccount, relatedAccounts, includeUsage }) => {
  const navigate = useNavigate();
  const isOwnSubscription = subscriptionAccount.email === relatedAccounts.primaryAccount.email;
  const [globalState, dispatch] = Store.useStore();
  const [editable, setEditable] = React.useState(isOwnSubscription && subscriptionAccount.subscription.manual);
  const [subscriptionUpdate, setSubscriptionUpdate] = React.useState(structuredClone(subscriptionAccount.subscription));
  const [changesMade, setChangesMade] = React.useState(false);
  const [newDomain, setNewDomain] = React.useState('');
  const [newDomainError, setNewDomainError] = React.useState('');
  const [showApplyNotice, setShowApplyNotice] = React.useState(false);

  React.useEffect(() => {
    if (isOwnSubscription) {
      setSubscriptionUpdate(structuredClone(subscriptionAccount.subscription));
      setTimeout(() => {
        setChangesMade(false);
      }, 50);
    }
  }, [subscriptionAccount.subscription]);

  React.useEffect(() => {
    setEditable(isOwnSubscription && subscriptionUpdate.manual);
  }, [subscriptionUpdate.manual]);

  React.useEffect(() => {
    setChangesMade(true);
  }, [subscriptionUpdate]);

  React.useEffect(() => {
    setChangesMade(false);
  }, []);

  React.useEffect(() => {
    if (changesMade) {
      setShowApplyNotice(true);
    }
  }, [changesMade]);

  const rules = [
    {
      type: 'entity',
      target: 'self',
      enabled: false,
    },
    {
      type: 'entity',
      target: 'team',
      enabled: false,
    },
    {
      type: 'entity',
      target: 'anyone',
      enabled: false,
    },
  ];
  subscriptionUpdate.endUserAssignmentRules.forEach((rule) => {
    switch (rule.target) {
      case 'self': rules[0].enabled = true; break;
      case 'team': rules[1].enabled = true; break;
      case 'anyone': rules[2].enabled = true; break;
      default:
    }
    if (rule.type === 'domain') {
      rules.push({
        ...rule,
        enabled: true,
      });
    }
  });
  const addDomainButtonRef = React.useRef();

  return (
    <Stack vertical>
      {(subscriptionAccount.email !== relatedAccounts.primaryAccount.email) && (
      <MessageBar
        styles={{ container: { width: 'fit-content' } }}
        text={(
          <>
            <Text block>
              {'This account is part of the '}
              <LinkButton
                text={subscriptionAccount.email}
                onClick={async () => {
                  navigate(`${Routing.getTree(globalState).home.routes.administration.routes.accounts.path}/${subscriptionAccount.uuid}`);
                }}
              />
              {' team'}
            </Text>
            <Text block>Navigate to the main account to manage its subscription</Text>
          </>
        )}
      />
      )}
      {isOwnSubscription && (
      <Stack horizontal>
        <Checkbox
          className={Theme.styles.tableRowCheckbox}
          checked={subscriptionUpdate.manual}
          onChange={async () => {
            setSubscriptionUpdate({
              ...subscriptionUpdate,
              manual: !subscriptionUpdate.manual,
            });
          }}
          label="Manually set allowances"
        />
      </Stack>
      )}

      <div style={{ margin: '10px 0 0 0' }}>
        <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText].join(' ')}>
          <tbody>
            <tr>
              <th>ID</th>
              <td>:</td>
              <td>{subscriptionAccount.uuid}</td>
            </tr>
            <tr>
              <th>Subscription owner</th>
              <td>:</td>
              <td>{subscriptionAccount.email}</td>
            </tr>
            <tr>
              <th>Refreshed</th>
              <td>:</td>
              <td>
                <Stack horizontal tokens={StackTokens.spacing}>
                  <span>
                    {new Date(subscriptionAccount.lastSynced).toLocaleString(navigator.languages[0])}
                  </span>
                  <CommandButton
                    text=""
                    iconProps={{
                      iconName: 'Refresh',
                    }}
                    className={[Theme.styles.outlineControlButton, Theme.styles.commandButton, componentStyles.refreshButton].join(' ')}
                    onClick={async () => {
                      // console.log('Force-refresh account', subscriptionAccount.uuid);
                      const updatedAccount = await AdminApi.getAccount(subscriptionAccount.uuid, { refresh: true });
                      if (updatedAccount.responseStatus !== 200) {
                        dispatch(UiReducer.uiSetErrors(updatedAccount.messages));
                        return;
                      }

                      const fRelatedAccounts = { ...relatedAccounts };
                      if (fRelatedAccounts.primaryAccount.uuid === updatedAccount.uuid) {
                        fRelatedAccounts.primaryAccount = updatedAccount;
                      } else {
                        const index = fRelatedAccounts.secondaryAccounts.findIndex((account) => account.uuid === updatedAccount.uuid);
                        if (index >= 0) {
                          fRelatedAccounts.secondaryAccounts[index] = updatedAccount;
                        }
                      }

                      AccountsState.setRelatedAccounts(dispatch, fRelatedAccounts);

                      if (isOwnSubscription) {
                        setSubscriptionUpdate(structuredClone(subscriptionAccount.subscription));
                      }
                    }}
                  />
                </Stack>
              </td>
            </tr>
            <tr>
              <th>Last seen</th>
              <td>:</td>
              <td>
                {new Date(subscriptionAccount.lastSeen).toLocaleString(navigator.languages[0])}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <header className={Theme.styles.sectionHeader}>Subscription Allowance</header>
      <div>
        <table className={[Theme.styles.table, Theme.styles.tableNoPadding, Theme.styles.tableText].join(' ')}>
          <tbody>
            <tr>
              <th>Apps saved to Cloud</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalCloudApps}</td>
                <td>/</td>
              </>
              )}
              <td>
                {editable ? (
                  <TextFieldBuffered
                    validateValue={(value) => {
                      let validatedValue;
                      try {
                        validatedValue = GraphUtils.validateInputValue({
                          value,
                          validationRules: { type: 'int', allowEmpty: false, min: 1 },
                        });
                      } catch (error) {
                        throw new Error(error.message);
                      }
                      return validatedValue;
                    }}
                    applyValue={(value) => {
                      setSubscriptionUpdate({
                        ...subscriptionUpdate,
                        maxCloudAppsPerCreator: value,
                      });
                    }}
                    actualValue={subscriptionUpdate.maxCloudAppsPerCreator}
                    className={componentStyles.smallTextInput}
                  />
                ) : (
                  <span>{subscriptionAccount.subscription.maxCloudAppsPerCreator}</span>
                )}
              </td>
            </tr>
            <tr>
              <th>Published apps</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalPublishedApps}</td>
                <td>/</td>
              </>
              )}
              <td>
                {editable ? (
                  <TextFieldBuffered
                    validateValue={(value) => {
                      let validatedValue;
                      try {
                        validatedValue = GraphUtils.validateInputValue({
                          value,
                          validationRules: { type: 'int', allowEmpty: false, min: 1 },
                        });
                      } catch (error) {
                        throw new Error(error.message);
                      }
                      return validatedValue;
                    }}
                    applyValue={(value) => {
                      setSubscriptionUpdate({
                        ...subscriptionUpdate,
                        maxPublishedCloudApps: value,
                      });
                    }}
                    actualValue={subscriptionUpdate.maxPublishedCloudApps}
                    className={componentStyles.smallTextInput}
                  />
                ) : (
                  <span>{subscriptionAccount.subscription.maxPublishedCloudApps}</span>
                )}
              </td>
            </tr>
            <tr>
              <th>Total users across all apps</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>{subscriptionAccount.subscription.totalEndUsers}</td>
                <td>/</td>
              </>
              )}
              <td>
                {editable ? (
                  <TextFieldBuffered
                    validateValue={(value) => {
                      let validatedValue;
                      try {
                        validatedValue = GraphUtils.validateInputValue({
                          value,
                          validationRules: { type: 'int', allowEmpty: false, min: 1 },
                        });
                      } catch (error) {
                        throw new Error(error.message);
                      }
                      return validatedValue;
                    }}
                    applyValue={(value) => {
                      setSubscriptionUpdate({
                        ...subscriptionUpdate,
                        maxUsersTotal: value,
                      });
                    }}
                    actualValue={subscriptionUpdate.maxUsersTotal}
                    className={componentStyles.smallTextInput}
                  />
                ) : (
                  <span>{subscriptionAccount.subscription.maxUsersTotal}</span>
                )}
              </td>
            </tr>
            <tr>
              <th>Users per app</th>
              <td>:</td>
              {includeUsage && (
              <>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </>
              )}
              <td>
                {editable ? (
                  <TextFieldBuffered
                    validateValue={(value) => {
                      let validatedValue;
                      try {
                        validatedValue = GraphUtils.validateInputValue({
                          value,
                          validationRules: { type: 'int', allowEmpty: false, min: 1 },
                        });
                      } catch (error) {
                        throw new Error(error.message);
                      }
                      return validatedValue;
                    }}
                    applyValue={(value) => {
                      setSubscriptionUpdate({
                        ...subscriptionUpdate,
                        maxUsersPerApp: value,
                      });
                    }}
                    actualValue={subscriptionUpdate.maxUsersPerApp}
                    className={componentStyles.smallTextInput}
                  />
                ) : (
                  <span>{subscriptionAccount.subscription.maxUsersPerApp}</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <header className={Theme.styles.sectionHeader}>End-user Assignment Rules</header>
      <div>
        <table>
          <tbody>
            {rules.map((rule) => {
              let label = rule.target;
              if (rule.type === 'entity') {
                label = label[0].toUpperCase() + label.slice(1);
              }
              if (rule.type === 'domain') {
                label = `*@${label}`;
              }
              return (
                <tr key={rule.type + rule.target}>
                  <td>
                    <Stack horizontalAlign="center">
                      <Checkbox
                        disabled={!isOwnSubscription}
                        className={Theme.styles.tableRowCheckbox}
                        checked={rule.enabled}
                        onChange={async () => {
                          // console.log('Rule toggle', rule);
                          const update = { ...subscriptionUpdate };
                          if (rule.enabled) {
                            // Find and remove this rule
                            const index = subscriptionUpdate.endUserAssignmentRules.findIndex((fRule) => fRule.target === rule.target);
                            subscriptionUpdate.endUserAssignmentRules.splice(index, 1);
                          } else if (rule.type === 'entity') {
                            const ruleCopy = { ...rule };
                            delete ruleCopy.enabled;
                            subscriptionUpdate.endUserAssignmentRules.push(ruleCopy);
                          }
                          setSubscriptionUpdate(update);
                        }}
                      />
                    </Stack>
                  </td>
                  <td>{label}</td>
                </tr>
              );
            })}
            {isOwnSubscription && (
            <tr>
              <td>
                <CommandButton
                  componentRef={addDomainButtonRef}
                  text=""
                  iconProps={{
                    iconName: 'AddTo',
                  }}
                  className={[Theme.styles.outlineControlButton, Theme.styles.commandButton, componentStyles.addDomainButton].join(' ')}
                  onClick={async () => {
                    // console.log('Add new domain to end-user assignment rules:', newDomain);
                    const update = { ...subscriptionUpdate };
                    const index = subscriptionUpdate.endUserAssignmentRules.findIndex((fRule) => fRule.target === newDomain);
                    if (index >= 0) {
                      setNewDomainError('Domain already added');
                      return;
                    }
                    if (!newDomain.match('^[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+$')) {
                      setNewDomainError('Invalid domain');
                      return;
                    }
                    update.endUserAssignmentRules.push({
                      type: 'domain',
                      target: newDomain,
                    });
                    setSubscriptionUpdate(update);
                    setNewDomain('');
                  }}
                />
              </td>
              <td>
                <TextField
                  value={newDomain}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 || event.key === 'Enter' || event.key === 'NumpadEnter') {
                      if (addDomainButtonRef.current) {
                        // eslint-disable-next-line no-underscore-dangle
                        addDomainButtonRef.current._onClick();
                      }
                    }
                  }}
                  onChange={(event, value) => {
                    let fValue = `${value}`.trim().toLowerCase();
                    fValue = fValue.replaceAll(/^(@|\.)/gi, '');
                    setNewDomainError('');
                    setNewDomain(fValue);
                  }}
                  placeholder="Add wildcard domain"
                  errorMessage={newDomainError}
                />
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>

      {showApplyNotice && changesMade && (
        <MessageBar
          styles={{ container: { width: 'fit-content' } }}
          text='Save all changes at once by clicking "Apply" below'
          messageBarType={MessageBarType.warning}
        />
      )}

      <header className={Theme.styles.sectionHeader}>Publishing</header>
      <Stack horizontal>
        <Checkbox
          disabled={!isOwnSubscription}
          className={Theme.styles.tableRowCheckbox}
          checked={subscriptionUpdate.featuresEnabled.includes('publicPublishing')}
          onChange={async () => {
            const update = { ...subscriptionUpdate };
            const index = update.featuresEnabled.findIndex((el) => el === 'publicPublishing');
            if (index >= 0) {
              update.featuresEnabled.splice(index, 1);
            } else {
              update.featuresEnabled.push('publicPublishing');
            }
            setSubscriptionUpdate(update);
          }}
          label="Allow publishing apps with public access (no end-user registration required)"
        />
      </Stack>

      <header className={Theme.styles.sectionHeader}>Metered Usage</header>

      <Stack styles={{ inner: { margin: '-10px 0 0 -10px' } }} horizontal wrap tokens={StackTokens.spacingWide}>
        {
        Object.values(subscriptionAccount.subscription.featuresUsage)
          .map((featureUsage) => (
            <FeatureUsage
              featureUsage={featureUsage}
              key={featureUsage.feature}
              setSubscriptionUpdate={setSubscriptionUpdate}
              editable={editable}
              subscriptionUpdate={subscriptionUpdate}
            />
          ))
      }
      </Stack>

      {isOwnSubscription && (
      <Stack vertical tokens={StackTokens.spacing}>
        <Separator />
        <Text>Apply changes made above to this subscription:</Text>
        <Stack horizontal tokens={StackTokens.spacing}>
          <PrimaryButton
            disabled={!changesMade}
            onClick={async () => {
              const updatedAccount = await AdminApi.updateSubscription(subscriptionAccount.uuid, subscriptionUpdate);
              if (updatedAccount.responseStatus !== 200) {
                dispatch(UiReducer.uiSetErrors(updatedAccount.messages));
                return;
              }
              AccountsState.setRelatedAccounts(dispatch, { ...relatedAccounts, primaryAccount: updatedAccount });
              setTimeout(() => {
                setChangesMade(false);
              }, 50);
            }}
            text="Apply"
            className={Theme.styles.outlineControlButton}
          />
          <DefaultButton
            disabled={!changesMade}
            onClick={() => {
              setSubscriptionUpdate(structuredClone(subscriptionAccount.subscription));
              setTimeout(() => {
                setChangesMade(false);
              }, 50);
            }}
            text="Cancel"
            className={Theme.styles.outlineControlButton}
          />
        </Stack>
      </Stack>
      )}

    </Stack>
  );
};
